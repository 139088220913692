import React from 'react';
import { graphql } from 'gatsby';
import DatoAsset from '../../../primitives/asset';
import Container from '../../../primitives/grid/container';
import { colors } from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Col from '../../../primitives/grid/col';
import Row from '../../../primitives/grid/row';
import Typography from '../../../primitives/typography';
import { StyledButtonAsLink } from '../../../primitives/styled-button';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import { getLink } from '../../../primitives/link';
import AspectRatio from '../../../primitives/aspect-ratio';

export type CustomerSuccessGrabCtaProps = Queries.CustomerSuccessGrabCtaPropsFragment;

const CustomerSuccessGrabCta = ({ backgroundImage, link, richTitle, richText }: CustomerSuccessGrabCtaProps): JSX.Element => (
    <div css={{
        position: 'relative',
    }}
    >
        {backgroundImage && (
            <AspectRatio
                breakpoints={{
                    dt: 'none',
                    mb: { width: 392, height: 318 },
                }}
                css={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    [breakpoints.mb]: {
                        position: 'relative',
                    },
                }}
            >
                <DatoAsset
                    css={{
                        height: '100%',
                        width: '100%',
                    }}
                    muted
                    autoPlay
                    asset={backgroundImage}
                    objectFit="cover"
                    objectPosition="center center"
                />
            </AspectRatio>
        )}
        <Container css={{
            paddingTop: '90px',
            paddingBottom: '90px',
            position: 'relative',
            [breakpoints.mb]: {
                paddingTop: '40px',
                paddingBottom: '0',
                marginBottom: '55px',
            },
        }}
        >
            <Row>
                <Col breakpoints={{ dt: { span: 6 } }}>
                    <div css={{
                        backgroundColor: colors.shadesWhite,
                        borderRadius: borderRadius.large,
                        padding: '40px',
                        boxShadow: '0 24px 54px #0004',
                        maxWidth: '392px',
                        [breakpoints.mb]: {
                            padding: '0',
                            boxShadow: 'none',
                            maxWidth: 'unset',
                        },
                    }}
                    >
                        {/* logo && (
                            <div css={{
                                marginBottom: '26px',
                                maxWidth: '100px',
                                width: '100%',
                                [breakpoints.mb]: {
                                    display: 'none',
                                },
                            }}
                            >
                                <DatoAsset asset={logo} />
                            </div>
                        ) */}
                        <Typography
                            override
                            css={{
                                marginBottom: '16px',
                                maxWidth: '280px',
                            }}
                            fontSize={{
                                dt: 2835,
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle} />
                        </Typography>
                        <Typography
                            override
                            css={{
                                marginBottom: '34px',
                                p: {
                                    marginBottom: '12px',
                                },
                                opacity: 0.8
                            }}
                            fontSize={{
                                dt: 1420,
                                tb: 1420,
                                mb: 1226,
                            }}
                        >
                            <CustomStructuredTextRenderer data={richText} />
                        </Typography>
                        {link && link.length > 0 && (
                            <StyledButtonAsLink
                                css={{
                                    [breakpoints.mb]: {
                                        width: '148px',
                                    },
                                }}
                                to={getLink(link[0].link)}
                            >
                                {link[0].title}
                            </StyledButtonAsLink>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export default CustomerSuccessGrabCta;

export const query = graphql`
    fragment CustomerSuccessGrabCtaProps on DatoCmsCustomerSuccessGrabCta {
        backgroundImage {
            ...DatoCmsInternalAsset
        }
        logo {
            ...DatoCmsInternalAsset
        }
        richTitle {
            blocks
            links
            value
        }
        richText {
            blocks
            links
            value
        }
        link {
            ...LinkWithTitleProps
        }
    } 
`;
